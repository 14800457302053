// Types
import * as CoreTypes from '../Models/Core.type'

/**
 * All networking url used in this app
 */
export default class CommonUrls {
  static path = process.env.REACT_APP_HOST;

  static get apiPath(): string {
    return `${CommonUrls.path || ''}/api`;
  }

  static _getUrl(
    path: string,
    { content, expand, size, dateStart, dateEnd, filter }: CoreTypes.IUrlQuery = {}
  ) {
    const params = []
    if (content !== undefined) {
      params.push(`content=${content.join(',')}`)
    }
    if (expand !== undefined) {
      params.push(`expand=${expand.join(',')}`)
    }
    if (size !== undefined) {
      params.push(`size=${size}`)
    }
    if (dateStart !== undefined && dateEnd !== undefined) {
      params.push(`period=${encodeURIComponent(JSON.stringify({
        dateStart,
        dateEnd
      }))}`)
    }
    if (filter !== undefined) {
      params.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`)
    }
    return `${CommonUrls.apiPath}/${path}?${params.join('&')}`
  }

  /**
   * LOGIN
   */
  static getLogin() {
    return `${CommonUrls.apiPath}/auth/login`;
  }
  static getForgotPassword() {
    return `${CommonUrls.apiPath}/lostpasswords/reset_ask`;
  }
  static getResetPassword(encrytedString: string) {
    return `${CommonUrls.apiPath}/lostpasswords/reset_update/${encrytedString}`;
  }
  static getLogout() {
    return `${CommonUrls.apiPath}/auth/logout`;
  }
  /**
   * PROFIL
   */
  static getUser(id: string) {
    return `${CommonUrls.apiPath}/persons/${id}?expand=all`;
  }
  static setUser(id: string) {
    return `${CommonUrls.apiPath}/persons/${id}`;
  }
  static getAddress() {
    return `${CommonUrls.apiPath}/addresses`;
  }
  static getAddressWhitId(id: string) {
    return `${CommonUrls.apiPath}/addresses/${id}`;
  }
  static getAccount(id: string) {
    return `${CommonUrls.apiPath}/accounts/${id}`;
  }
  static getAccessTypes() {
    return `${CommonUrls.apiPath}/accesstypes`;
  }
  static getProfil(id: string) {
    return `${CommonUrls.apiPath}/profiles/${id}`;
  }
  /**
   * IMAGES
   */
  static uploadImage() {
    return `${CommonUrls.apiPath}/images/upload`;
  }
  static deleteImage(id: string) {
    return `${CommonUrls.apiPath}/images/${id}`;
  }
  static deleteLayerMapImageLegend(fklayerMapId: string, legendtype: string) {
    return `${CommonUrls.apiPath}/layermapimages/${fklayerMapId}/legend/${legendtype}`;
  }
  /**
   *  POI
   */
  static getPois({ expand = ['all'], ...params }: CoreTypes.IUrlQuery = {}) {
    return CommonUrls._getUrl('pois', {
      content: ['total', 'provenance', 'countPhaseType', 'countLinkType'],
      expand,
      ...params
    })
  }
  static getPoi(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}?expand=all&content=provenance`;
  }
  static updatePoi(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}`;
  }
  static deletePoi(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}`;
  }
  static exportProjectInfo(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}/export`;
  }
  static exportResourceInfo(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}/export`;
  }
  static getPoiLinks(params?: CoreTypes.IUrlQuery) {
    return CommonUrls._getUrl('poilinks', params)
  }
  static addPoiLink() {
    return `${CommonUrls.apiPath}/poilinks`
  }
  static updatePoiLink(fkpoiFrom: string, fkpoiTo: string) {
    return `${CommonUrls.apiPath}/poilinks/${fkpoiFrom}%2C${fkpoiTo}`
  }
  static updatePoiPhase(id: string) {
    return `${CommonUrls.apiPath}/poiphases/${id}`;
  }
  static savePoiPhase() {
    return `${CommonUrls.apiPath}/poiphases`;
  }
  static deletePoiPhase(id: string) {
    return `${CommonUrls.apiPath}/poiphases/${id}`;
  }
  static deletePoiLink(fkpoiFrom: string, fkpoiTo: string) {
    return `${CommonUrls.apiPath}/poilinks/${fkpoiFrom}%2C${fkpoiTo}`
  }
  static savePoiThematics() {
    return `${CommonUrls.apiPath}/poithematics`;
  }
  static deletePoiThematic(thematicId: string, poiId: string) {
    return `${CommonUrls.apiPath}/poithematics/${thematicId}%2C${poiId}`
  }
  static savePoiAllowedPersons() {
    return `${CommonUrls.apiPath}/poiallowedpersons`;
  }
  static deletePoiAllowedPerson(poiId: string, allowedPerson: string) {
    return `${CommonUrls.apiPath}/poiallowedpersons/${poiId}%2C${allowedPerson}`
  }
  static savePoiAssociatedPerson() {
    return `${CommonUrls.apiPath}/poiassociatedpersons`;
  }
  static updatePoiAssociatedPerson(poiId: string, personId: string) {
    return `${CommonUrls.apiPath}/poiassociatedpersons/${personId}%2C${poiId}`;
  }
  static deletePoiAssociatedPerson(poiId: string, personId: string) {
    return `${CommonUrls.apiPath}/poiassociatedpersons/${personId}%2C${poiId}`;
  }
  static savePoiFile(id: string, folderId?: string) {
    return `${CommonUrls.apiPath}/pois/${id}/${folderId !== undefined ? `folders/${folderId}/` : ''}fileupload?overwrite=true`
  }
  static updatePoiFile(id: string, fileId: string) {
    return `${CommonUrls.apiPath}/pois/${id}/files/${fileId}`
  }
  static deletePoiFile(id: string, fileId: string) {
    return `${CommonUrls.apiPath}/pois/${id}/files/${fileId}`
  }
  static savePoiFolder(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}/folders`
  }
  static savePoiFolderByPath(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}/folders/bypath`
  }
  static updatePoiFolder(id: string, folderId: string) {
    return `${CommonUrls.apiPath}/pois/${id}/folders/${folderId}`
  }
  static deletePoiFolder(id: string, folderId: string) {
    return `${CommonUrls.apiPath}/pois/${id}/folders/${folderId}`
  }
  static getPoiFolders(id: string) {
    return `${CommonUrls.apiPath}/pois/${id}/folder?depth=-1`
  }

  /**
   *  VALUE SLOTS
   */
  static saveValueSlot() {
    return `${CommonUrls.apiPath}/valueslots`
  }
  static updateValueSlot(id: string) {
    return `${CommonUrls.apiPath}/valueslots/${id}`
  }
  static deleteValueSlot(id: string) {
    return `${CommonUrls.apiPath}/valueslots/${id}`
  }

  /**
   *  Persons
   */
  static savePerson() {
    return `${CommonUrls.apiPath}/persons`
  }
  static deletePerson(id: string) {
    return `${CommonUrls.apiPath}/persons/${id}`
  }

  /**
   *  Financing
  */
  static saveExpenseLine() {
    return `${CommonUrls.apiPath}/expenselines`
  }
  static updateExpenseLine(id: string) {
    return `${CommonUrls.apiPath}/expenselines/${id}`
  }
  static deleteExpenseLine(id: string) {
    return `${CommonUrls.apiPath}/expenselines/${id}`
  }
  static saveExpenseFund() {
    return `${CommonUrls.apiPath}/expensefundings`
  }
  static updateExpenseFund(id: string) {
    return `${CommonUrls.apiPath}/expensefundings/${id}`
  }
  static deleteExpenseFund(id: string) {
    return `${CommonUrls.apiPath}/expensefundings/${id}`
  }
  static saveExpenseEngaged() {
    return `${CommonUrls.apiPath}/expenseengaged`
  }
  static updateExpenseEngaged(id: string) {
    return `${CommonUrls.apiPath}/expenseengaged/${id}`
  }
  static deleteExpenseEngaged(id: string) {
    return `${CommonUrls.apiPath}/expenseengaged/${id}`
  }

  // DATA
  static getPhaseTypes() {
    return `${CommonUrls.apiPath}/phasetypes?expand=all`;
  }
  static getPoiTypes() {
    return `${CommonUrls.apiPath}/poitypes?expand=all`;
  }
  static getFundTypes() {
    return `${CommonUrls.apiPath}/fundtypes`;
  }
  static getThematic() {
    return `${CommonUrls.apiPath}/thematic`
  }
  static getTerritories() {
    return `${CommonUrls.apiPath}/territories`
  }
  static getPhase() {
    return `${CommonUrls.apiPath}/phasetypes`
  }
  static getPersonsWithAccount() {
    const filter = encodeURIComponent(JSON.stringify({
      fkaccount: {
        $isnotnull: true
      }
    }))
    return `${CommonUrls.apiPath}/persons?filter=${filter}&expand=account,accessType`
  }
  static getPersons(filter?: any) {
    let params = 'expand=all&content=total'
    if (filter !== undefined) {
      params += `&filter=${encodeURIComponent(JSON.stringify(filter))}`
    }
    return `${CommonUrls.apiPath}/persons?${params}`
  }
  static getPositions() {
    return `${CommonUrls.apiPath}/positions`
  }
  static getLinkTypes() {
    return `${CommonUrls.apiPath}/linktypes?expand=all`;
  }

  // Dataviz
  static getDatavizPalettes() {
    return `${process.env.PUBLIC_URL}/data/dataviz/datavizPalettes.json`
  }
  static getDashboardDatavizComposition() {
    return `${process.env.PUBLIC_URL}/data/dataviz/datavizDashboardComposition.json`
  }
  static getProjectDatavizComposition() {
    return `${process.env.PUBLIC_URL}/data/dataviz/datavizProjectComposition.json`
  }


  /**
   * Converts the actual URL of the new frontend to the URL of the classic frontend.
   * 
   * If URL start with 'ng.' (i.e. ng.ENVNAME.mapinaction.com) return the URL 
   * without it (i.e. ENVNAME.mapinaction.com).
   * If URL deos NOT start with 'ng.' (i.e. ENVNAME.mapinaction.com) return the URL
   * with the 'classis.' prefix (i.e. classic.ENVNAME.mapinaction.com).
   * 
   * @returns the correct URL of the classic frontend
   */
  static getUrlForClassicFrontend() {
    const serverUrlRegEx = /^ng\.(.+)$/
    const [, url] = serverUrlRegEx.exec(window.location.hostname) ?? []
    return url ?? `classic.${window.location.hostname}`
  }
  // Admin
  static getAdminPanel() {
    const url = this.getUrlForClassicFrontend()
    return `https://${url}/mia/#pckgview/PnpPrj/pnpcomPckgSubViewAdministration/eyJ0eXBlIjoiY3VzdG9tQ2hpbGRSb3V0ZSIsInBhcmFtcyI6eyJlbnRpdHlOYW1lIjoicG9pdHlwZXMiLCJ0aXRsZSI6IlR5cGVzIiwiaWQiOjE2NDMyOTc2OTQ3ODUsInR5cGUiOiJyZWd1bGFyIiwiZGlzcGxheSI6Imxpc3RkZXRhaWwiLCJhY3Rpb24iOiJyZWFkIiwicmVjb3JkSWQiOiI0OThjMmUzOS0zNWRlLTQzZGItODE3OS03YjVkMjNmYTQwNDUifX0%3D`
  }

  static getAddressesPanel() {
    const url = this.getUrlForClassicFrontend()
    return `https://${url}/mia/#pckgview/PnpPrj/pnpprjPckgSubViewAdminResources/eyJ0eXBlIjoiY3VzdG9tQ2hpbGRSb3V0ZSIsInBhcmFtcyI6eyJzdWJWaWV3VHlwZSI6InBucHByakFkbWluQWRkcmVzc2VzVmlldyIsImlkIjoxNjQzMzUzMDMyODg2LCJlbmFibGVBZGRyZXNzU2VsZWN0aW9uIjp0cnVlLCJtb2RlbFZpZXdSZWNvcmRQYXRoIjoicmVjb3JkLmFkZHJlc3MifX0%3D`
  }
}
